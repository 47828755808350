import { TEXT_VARIANTS } from 'constants/text';
import React from 'react';
import LogoIcon from 'style-book/icons/LogoIcon';
import TextComponent from 'style-book/text/TextComponent';

const NotFoundPage = () => {
  return (
    <div className="page-not-found-screen">
      <TextComponent variant={TEXT_VARIANTS.H1}>
        {"It's seems like we did not found what you have looked for..."}
      </TextComponent>
      <div className="glasses-container">
        <LogoIcon className="icon" />
        <div className="divider" />
        <LogoIcon className="icon" />
      </div>
    </div>
  );
};

export default NotFoundPage;
