import PropTypes from 'prop-types';
import React from 'react';

export default function LogoIcon({ className }) {
  return (
    <svg className={className} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="22.5" stroke="url(#paint0_linear_435_546)" strokeWidth="3" />
      <circle cx="24" cy="24" r="14.5" stroke="url(#paint1_linear_435_546)" strokeWidth="3" />
      <circle cx="24" cy="24" r="4.5" stroke="url(#paint2_linear_435_546)" strokeWidth="3" />
      <defs>
        <linearGradient
          id="paint0_linear_435_546"
          x1="24"
          y1="0"
          x2="24"
          y2="48"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#069999" />
          <stop offset="1" stopColor="#EC6568" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_435_546"
          x1="24"
          y1="8"
          x2="24"
          y2="40"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#069999" />
          <stop offset="1" stopColor="#EC6568" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_435_546"
          x1="24"
          y1="18"
          x2="24"
          y2="30"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#069999" />
          <stop offset="1" stopColor="#EC6568" />
        </linearGradient>
      </defs>
    </svg>
  );
}

LogoIcon.propTypes = {
  className: PropTypes.string
};
