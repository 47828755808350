import classnames from 'classnames';
import { TEXT_STATUS, TEXT_VARIANTS } from 'constants/text';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import './TextComponent.scss';

function TextComponent({ variant, role, status, children, className, forwardRef, ...props }) {
  const inputRef = useRef();

  if (forwardRef) {
    forwardRef.current = inputRef.current;
  }
  const actualClassName = classnames('text-component', variant, {
    [className]: className,
    [status]: status
  });

  const actualProps = { ...props, className: actualClassName, ref: inputRef };

  if (variant === TEXT_VARIANTS.H1) {
    return <h1 {...actualProps}>{children}</h1>;
  } else if (variant === TEXT_VARIANTS.H2) {
    return <h2 {...actualProps}>{children}</h2>;
  } else if (variant === TEXT_VARIANTS.H3) {
    return <h3 {...actualProps}>{children}</h3>;
  } else if (
    variant === TEXT_VARIANTS.PARAGRAPH ||
    variant === TEXT_VARIANTS.PARAGRAPH_STRONG ||
    variant === TEXT_VARIANTS.PARAGRAPH_MEDIUM ||
    variant === TEXT_VARIANTS.PARAGRAPH_BOLD
  ) {
    return <span {...actualProps}>{children}</span>;
  } else if (variant === TEXT_VARIANTS.LINK) {
    return <a {...actualProps}>{children}</a>;
  } else if (variant === TEXT_VARIANTS.BULLET) {
    return <li {...actualProps}>{children}</li>;
  } else if (variant === TEXT_VARIANTS.INTERNAL_LINK) {
    return (
      <Link {...actualProps} to={actualProps.href}>
        {children}
      </Link>
    );
  }
  return <p {...actualProps}>{children}</p>;
}

TextComponent.propTypes = {
  variant: PropTypes.oneOf(Object.values(TEXT_VARIANTS)),
  status: PropTypes.oneOf(Object.values(TEXT_STATUS)),
  children: PropTypes.any,
  className: PropTypes.string,
  forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.node })])
};

TextComponent.defaultProps = {
  variant: TEXT_VARIANTS.PARAGRAPH_MEDIUM,
  status: TEXT_STATUS.INFO
};

export default TextComponent;
