export const TEXT_VARIANTS = Object.freeze({
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  PARAGRAPH: 'paragraph',
  PARAGRAPH_STRONG: 'paragraph-strong',
  PARAGRAPH_MEDIUM: 'paragraph-medium',
  PARAGRAPH_BOLD: 'paragraph-bold',
  INTERNAL_LINK: 'internal-link',
  LINK: 'link',
  CONTAINER: 'container',
  BULLET: 'bullet'
});

export const TEXT_STATUS = Object.freeze({ INFO: 'info', ERROR: 'error', SUCCESS: 'success' });
